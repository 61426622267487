<template>
  <div class="brand-page" :class="themeClass"  :style="style">
    <div>
      <BlocksContentArea
        v-for="(block, index) in blocks"
        :key="'BrandPage-' + block?.ContentLink?.Expanded?.__typename + '-' + index"
        :block="block?.ContentLink?.Expanded"
        :data-epi-edit="'ContentArea'"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { type BrandPageFragment } from '#gql';

const props = defineProps<{
  page: BrandPageFragment,
  isMobile: boolean,
  isEditMode: boolean
}>();

const blocks = computed(() => {
  return (
    props.page.ContentArea?.filter(
      (block) => block?.ContentLink?.Expanded !== null
    ) || []
  );
});

const themeClass = computed(() => {
  if (props.page.Theme === 'VoltTheme') {
    return 'brand-page-theme-volt';
  } else if (props.page.Theme === 'ZynTheme') {
    return 'brand-page-theme-zyn';
  }
  return '';
});

const style = computed(() => {
  if (props.page.TakeOverColorSettings?.TakeOverBackgroundColor) {
    return `background-color: ${props.page.TakeOverColorSettings?.TakeOverBackgroundColor}`;
  } else {
    return '';
  }
});
</script>
<style>
.brand-page-theme-volt {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Platform', sans-serif !important;
  }
}

.brand-page-theme-zyn {
  @apply bg-grey500;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'DIN Pro', sans-serif !important;
  }

  .heading-xl-text {
    @screen lg {
      font-size: 65px;
      line-height: 65px;
    }
  }
}
</style>
